import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import authenticationService from '@/services/authentication.service';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/select',
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: () => import(/* webpackChunkName: "authentication-chunk" */ '../views/authentication.vue'),
    meta: {
      title: 'EZ Fraze',
    },
  },
  {
    path: '/convert',
    name: 'Convert',
    component: () => import(/* webpackChunkName: "convert-chunk" */ '../views/convert.vue'),
    props: true,
  },
  // TODO: SHOULD BE REMOVED
  {
    path: '/learn/:projectId?',
    name: 'Learn',
    component: () => import(/* webpackChunkName: "learn-chunk" */ '../views/learn.vue'),
    props: true,
  },
  {
    path: '/learn-names/:projectId?',
    name: 'LearnNames',
    component: () => import(/* webpackChunkName: "learn-names-chunk" */ '../views/learnNames.vue'),
    props: true,
  },
  {
    path: '/select/:projectId?',
    name: 'Select',
    component: () => import(/* webpackChunkName: "select2-chunk" */ '../views/select.vue'),
    props: true,
  },
  {
    path: '/edit-cards',
    name: 'Edit cards',
    component: () => import(/* webpackChunkName: "edit-chunk" */ '../views/editCards.vue'),
    props: true,
  },
  {
    path: '/analyse',
    name: 'Analyse',
    component: () => import(/* webpackChunkName: "select2-chunk" */ '../views/analyseSentences.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
    document.title = to.meta.title as string;

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/authentication', '/version'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired && !authenticationService.isLoggedIn) {
      next({ path: '/authentication' });
    } else if (authenticationService.isLoggedIn && to.path === '/authentication') {
      next({ path: '/' });
    } else {
      next();
    }
  },
);

export default router;
