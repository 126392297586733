<template>
  <div class="navbar">
    <a
      role="button"
      class="navbar-burger"
      v-bind:class="isMenuActive ? 'is-active' : ''"
      v-on:click="onHamburgerClick()"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
    <div id="nav" class="navbar-menu" v-bind:class="isMenuActive ? 'is-active' : ''">
      <div class="navbar-start" v-on:click="onMenuItemClick()">
        <router-link to="/convert" class="navbar-item">Convert</router-link>
        <router-link to="/learn" class="navbar-item">Learn</router-link>
        <router-link to="/select" class="navbar-item">Select</router-link>
        <router-link to="/edit-cards" class="navbar-item">Edit cards</router-link>
        <router-link to="/analyse" class="navbar-item">Analyse Pack</router-link>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class App extends Vue {
  isMenuActive = false;

  onMenuItemClick(): void {
    this.isMenuActive = false;
  }

  onHamburgerClick(): void {
    this.isMenuActive = !this.isMenuActive;
  }
}
</script>
