import md5 from 'md5';
import Observer from '@/helpers/observer';

const storageKey = 'ezfraze-analyse';
const myUserToken = 'whatever';
const myHash = '4586c2eed2a2e2d58830786b34bbb320'; // Password is in the repository's readme
const myName = 'ezfraze';

export class AuthenticationService {
  isLoggedIn$ = new Observer<boolean>(!!localStorage.getItem(storageKey));

  get isLoggedIn(): boolean {
    const token = localStorage.getItem(storageKey);
    return !!token && this.validate(token);
  }

  private validate(token: string): boolean {
    return token === myUserToken;
  }

  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem(storageKey);
    this.isLoggedIn$.value = false;
  }

  login(name: string, password: string): void {
    if (name !== myName && md5(password) !== myHash) {
      return;
    }
    localStorage.setItem(storageKey, myUserToken);
    this.isLoggedIn$.value = true;
  }
}

export default new AuthenticationService();
