
import { Vue } from 'vue-class-component';

export default class App extends Vue {
  isMenuActive = false;

  onMenuItemClick(): void {
    this.isMenuActive = false;
  }

  onHamburgerClick(): void {
    this.isMenuActive = !this.isMenuActive;
  }
}
